import * as React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Link as GatsbyLink, graphql } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import CloseSvg from '@icons/close.svg';

import { center, linkHover } from '@mixins';
import mq, { mqdown } from '@mq';
import Navigation from '@core/Navigation';
import BackLink from '@components/BackLink';

const withModal = Component => {
  const ComponentWithModal = props => (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => {
        const type = getContentType(props.data);
        if (modal) {
          return (
            <>
              <ModalStyles type={type} />
              <ModalContainer>
                <CloseButton to={closeTo}>
                  <CloseSvg />
                </CloseButton>
                <ModalBackLink to={closeTo} />
                <Component {...props} modal={modal} closeTo={closeTo} />
              </ModalContainer>
              <ModalBottomSpacer />
            </>
          );
        }

        return (
          <>
            <Navigation />
            <PageContainer type={type}>
              <Component {...props} />
            </PageContainer>
          </>
        );
      }}
    </ModalRoutingContext.Consumer>
  );

  return ComponentWithModal;
};

const ModalContainer = styled.main`
  padding-top: var(--navigation-height);
  padding-bottom: calc(var(--space) * 4);
  background: var(--color-white);

  ${mq.medium} {
    padding-top: var(--space-double);
  }
`;

const ModalBottomSpacer = styled.div`
  height: 4rem;

  ${mqdown.medium} {
    display: none;
  }
`;

const PageContainer = styled.main`
  padding-top: var(--space);
  ${getContentWidth};

  ${mq.medium} {
    padding-top: calc(4rem + var(--space-double));
  }
`;

const CloseButton = styled(GatsbyLink)`
  position: absolute;
  top: var(--space);
  right: var(--space);
  padding: var(--space);
  ${center};
  ${linkHover};

  ${mqdown.medium} {
    display: none;
  }
`;

const ModalBackLink = styled(BackLink)`
  ${mq.medium} {
    display: none;
  }
`;

const ModalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    height: 100%;
    top: 0;
    background-color: rgba(0,0,0,0.8) !important;
    overflow: auto;

    ${mq.medium} {
      top: var(--navigation-height) !important;
    }
    

    ${mqdown.medium} {
      /* width: 100vw; */
    }
  }

  .ReactModal__Content {
    position: absolute;
    margin: 0 auto;
    border-radius: 0 !important;
    border: none !important;
    padding: 0 !important;
    inset: 0 !important;
    overflow: initial !important;
    width: 100%;
    ${getContentWidth};

    ${mq.medium} {
      inset: 2rem var(--space-double) 0 !important;
    }
  }

  body.ReactModal__Body--open {
    overflow: hidden;
  }
`;

function getContentType(type) {
  if (type.contentfulProject) {
    return 'project';
  }
  if (type.contentfulTextArticle) {
    return 'textArticle';
  }
  if (type.contentfulArticle) {
    return 'article';
  }
  if (type.contentfulLocation) {
    return 'location';
  }

  return null;
}

function getContentWidth({ type }) {
  switch (type) {
    case 'project':
    case 'textArticle':
    case 'article':
    case 'location':
    default:
      return css`
        max-width: 800px;
      `;
  }
}

export default withModal;
