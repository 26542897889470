import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import Seo from '@core/Seo';
import renderRichText from '@common/renderRichText';
import BackLink from '@components/BackLink';
import withModal from '@common/withModal';
import mq, { mqdown } from '@mq';
import { richText, hyphenate, linkHover } from '@mixins';

import Image from '@core/Image';

const ArticlePage = ({
  modal,
  data: {
    contentfulArticle: {
      title,
      date,
      content,
      image,
      seoImage: {
        gatsbyImageData: {
          images: {
            fallback: { src: seoImage },
          },
        },
      },
    },
  },
}) => (
  <>
    <Seo pageTitle={title} pageImage={seoImage} />
    {!modal && <BackLink to="/aktuelles" />}
    <Header>
      <Title>{title}</Title>
      <div>{date}</div>
    </Header>
    <ArticleImage image={image} />
    <Content>{renderRichText(content)}</Content>
  </>
);

const Title = styled.h1`
  font: var(--font-h3);
`;

const Header = styled.header`
  display: grid;
  row-gap: 0.5rem;
  margin-bottom: var(--space-double);

  ${mq.medium} {
    row-gap: 0.75rem;
  }
`;

const ArticleImage = styled(Image)``;

const Content = styled.div`
  ${richText};
  ${hyphenate};
  font: var(--font-default);
  margin-top: var(--space-double);

  a[target='_blank'] {
    text-transform: uppercase;
    text-decoration: none;
    font: var(--font-small);
    ${linkHover};
  }

  ${mq.medium} {
    margin-top: 3.5rem;
  }
`;

export const query = graphql`
  query ($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      title
      date(formatString: "D.M.YYYY")
      content {
        raw
      }
      seoImage: image {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: JPG
          quality: 80
          resizingBehavior: FILL
        )
      }
      image {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          width: 740
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
          quality: 80
        )
      }
    }
  }
`;

export default withModal(ArticlePage);
