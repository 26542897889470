/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { linkHover } from '@mixins';
import mq from '@mq';

const BackLink = ({ to, className }) => (
  <Link to={to} className={className}>
    ← Zurück
  </Link>
);

const Link = styled(GatsbyLink)`
  text-transform: uppercase;
  font: var(--font-small);
  display: block;
  ${linkHover};

  margin-bottom: 1rem;

  ${mq.medium} {
    margin-bottom: 2rem;
  }
`;

export default BackLink;
